export async function updateLoopsContact(contactData) {
  try {
    // Debug log
    console.log('Attempting Loops update with data:', contactData);
    
    if (!contactData || !contactData.email) {
      throw new Error('Email is required for Loops contact update');
    }
    
    const response = await fetch('/api/update-loops', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData)
    });

    const data = await response.json();
    console.log('Loops API response:', data);
    
    if (!response.ok) {
      throw new Error(data.error || 'Failed to update Loops contact');
    }

    return data;
  } catch (error) {
    console.error('Error updating Loops contact:', error);
    throw error;
  }
} 