import React, { useState, useEffect } from 'react';
import { useAuth } from "../../util/auth";
import { useMetrics } from "../../util/UserMetricsContext";
import { BeakerIcon, SparklesIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import NewFeatureFlag from '../general/NewFeatureFlag';

// Enhanced preview cards component to match actual insight cards
const InsightPreviewCards = () => (
  <div className="relative w-full max-w-2xl mx-auto h-[280px]">
    {/* Success Preview Card */}
    <motion.div
      initial={{ opacity: 0, x: -20, y: 20 }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      transition={{ delay: 0.2, duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
      className="absolute left-4 top-4 w-72 p-6 bg-white rounded-xl shadow-lg border-l-4 border-emerald-500"
    >
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <div className="p-2 bg-emerald-100 rounded-lg">
            <SparklesIcon className="h-5 w-5 text-emerald-600" />
          </div>
        </div>
        <div className="flex-1 space-y-1">
          <div className="h-4 w-3/4 bg-emerald-100 rounded animate-pulse" />
          <div className="h-3 w-full bg-gray-100 rounded animate-pulse mt-2" />
          <div className="h-3 w-5/6 bg-gray-100 rounded animate-pulse mt-1" />
        </div>
      </div>
    </motion.div>

    {/* Warning Preview Card */}
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
      className="absolute right-4 top-24 w-72 p-6 bg-white rounded-xl shadow-lg border-l-4 border-amber-500"
    >
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <div className="p-2 bg-amber-100 rounded-lg">
            <ChartBarIcon className="h-5 w-5 text-amber-600" />
          </div>
        </div>
        <div className="flex-1 space-y-1">
          <div className="h-4 w-2/3 bg-amber-100 rounded animate-pulse" />
          <div className="h-3 w-full bg-gray-100 rounded animate-pulse mt-2" />
          <div className="h-3 w-4/5 bg-gray-100 rounded animate-pulse mt-1" />
        </div>
      </div>
    </motion.div>

    {/* Observation Preview Card */}
    <motion.div
      initial={{ opacity: 0, x: 20, y: 20 }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      transition={{ delay: 0.6, duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
      className="absolute left-16 top-44 w-72 p-6 bg-white rounded-xl shadow-lg border-l-4 border-blue-500"
    >
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <div className="p-2 bg-blue-100 rounded-lg">
            <BeakerIcon className="h-5 w-5 text-blue-600" />
          </div>
        </div>
        <div className="flex-1 space-y-1">
          <div className="h-4 w-5/6 bg-blue-100 rounded animate-pulse" />
          <div className="h-3 w-full bg-gray-100 rounded animate-pulse mt-2" />
          <div className="h-3 w-3/4 bg-gray-100 rounded animate-pulse mt-1" />
        </div>
      </div>
    </motion.div>
  </div>
);

const DataStory = () => {
  const auth = useAuth();
  const { metricsData } = useMetrics();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [stories, setStories] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [error, setError] = useState(null);
  const [selectedStory, setSelectedStory] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isInitializing, setIsInitializing] = useState(true);

  // Add progress bar animation
  useEffect(() => {
    let progressInterval;
    if (isAnalyzing) {
      setProgress(0);
      progressInterval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + Math.random() * 15;
        });
      }, 600);
    } else {
      setProgress(100);
    }

    return () => clearInterval(progressInterval);
  }, [isAnalyzing]);

  // Check for data initialization
  useEffect(() => {
    if (metricsData?.quarterlyData) {
      setIsInitializing(false);
    }
  }, [metricsData]);

  // Don't show empty state during initialization
  if (isInitializing) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500 mx-auto"></div>
          <p className="mt-4 text-gray-500">Loading your metrics data...</p>
        </div>
      </div>
    );
  }

  // Only show empty state if we're sure there's no data
  if (!metricsData?.quarterlyData || Object.keys(metricsData.quarterlyData).length === 0) {
    return <EmptyState onClick={analyzeData} />;
  }

  const analyzeData = async () => {
    setIsAnalyzing(true);
    setStories([]);
    setError(null);
    
    let controller;
    let timeoutId;
    try {
      if (!metricsData?.quarterlyData || Object.keys(metricsData.quarterlyData).length === 0) {
        throw new Error('No quarterly metrics data available');
      }

      const quarters = Object.keys(metricsData.quarterlyData).sort().reverse();
      const mostRecentQuarter = quarters[0];

      const formattedData = {
        userMetrics: metricsData.quarterlyData,
        aggregateData: metricsData.aggregateData[mostRecentQuarter] || {},
        historicalAggregateData: metricsData.aggregateData || {},
        timeframe: mostRecentQuarter,
        allTimeframes: quarters
      };

      controller = new AbortController();
      timeoutId = setTimeout(() => controller.abort(), 60000);

      const response = await fetch('/api/openai-analyse-metrics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      const data = await response.json();

      if (!data.success) {
        throw new Error(data.stories?.[0]?.details || 'Failed to analyze metrics');
      }

      if (!Array.isArray(data.stories) || data.stories.length === 0) {
        throw new Error('No insights found in the response');
      }

      const processedStories = data.stories.map(story => ({
        ...story,
        content: story.content,
        type: determineStoryType(story.type),
        finding: story.finding || '',
        details: story.details || '',
        actions: story.actions || ''
      }));

      setStories(processedStories);

    } catch (error) {
      console.error('Analysis error:', error);
      setError(error.message || 'Failed to analyze metrics');
    } finally {
      if (controller) {
        clearTimeout(timeoutId);
      }
      setIsAnalyzing(false);
    }
  };

  // Enhanced story type detection
  const determineStoryType = (type) => {
    // Map OpenAI response types to UI types
    switch (type?.toLowerCase()?.trim()) {
      case 'achievement':
        return 'success';
      case 'warning':
      case 'observation':
        return 'improvement';
      default:
        return 'improvement'; // Default to improvement for unknown types
    }
  };

  // Enhanced story type configuration
  const getStoryType = (story) => {
    // Use the processed type directly instead of analyzing content
    const isPositive = story.type === 'success';
    
    return {
      type: story.type,
      label: isPositive ? 'Good News Story' : 'Case for Change',
      icon: isPositive ? (
        <svg className="h-5 w-5 text-emerald-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ) : (
        <svg className="h-5 w-5 text-amber-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      ),
      decorativeIcon: isPositive ? (
        <svg className="w-full h-full text-emerald-500/5" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
        </svg>
      ) : (
        <svg className="w-full h-full text-amber-500/5" fill="currentColor" viewBox="0 0 24 24">
          <path d="M13 2L3 14h9l-1 8L21 10h-9l1-8z" />
        </svg>
      ),
      bgClass: isPositive ? 'from-emerald-50/90 to-white' : 'from-amber-50/90 to-white',
      tagClass: isPositive ? 'bg-emerald-100 text-emerald-800' : 'bg-amber-100 text-amber-800',
      numberClass: isPositive ? 'text-emerald-600' : 'text-amber-600',
      gradientClass: isPositive ? 'from-emerald-400 to-teal-500' : 'from-amber-400 to-orange-500'
    };
  };

  // Extract title from story content
  const getStoryTitle = (content) => {
    const firstLine = content.split('\n')[0];
    return firstLine.replace(/^\d+\.\s*/, '').substring(0, 60) + (firstLine.length > 60 ? '...' : '');
  };

  // Format the story content with beautiful styling
  const formatStoryContent = (content) => {
    const sections = content.split('\n').filter(s => s.trim());
    
    // Helper function to get icon and color based on type
    const getTypeStyles = (type) => {
      switch(type?.toLowerCase()) {
        case 'warning':
          return {
            icon: `<svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>`,
            bgColor: 'bg-amber-50',
            textColor: 'text-amber-700',
            borderColor: 'border-amber-200',
            tagBg: 'bg-amber-100',
            tagText: 'text-amber-800'
          };
        case 'achievement':
          return {
            icon: `<svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>`,
            bgColor: 'bg-emerald-50',
            textColor: 'text-emerald-700',
            borderColor: 'border-emerald-200',
            tagBg: 'bg-emerald-100',
            tagText: 'text-emerald-800'
          };
        default:
          return {
            icon: `<svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>`,
            bgColor: 'bg-blue-50',
            textColor: 'text-blue-700',
            borderColor: 'border-blue-200',
            tagBg: 'bg-blue-100',
            tagText: 'text-blue-800'
          };
      }
    };

    // Get type from first line
    const type = sections.find(s => s.includes('Type:'))?.split(':')[1]?.trim();
    const styles = getTypeStyles(type);
    
    // Extract action items with improved parsing
    const actionItemsStart = sections.findIndex(s => s.includes('Action Items:'));
    const actionItems = [];
    
    if (actionItemsStart !== -1) {
      // Get all lines after "Action Items:" until the next section or end
      for (let i = actionItemsStart + 1; i < sections.length; i++) {
        const line = sections[i].trim();
        // Stop if we hit another section
        if (line.includes('Type:') || line.includes('Pattern ID:') || 
            line.includes('Key Finding:') || line.includes('Details:')) {
          break;
        }
        // Add line if it starts with a number or bullet
        if (line.match(/^\d+\.|\d+\)|-|•/)) {
          actionItems.push(line.replace(/^\d+\.|\d+\)|-|•/, '').trim());
        }
      }
    }

    return `
      <div class="space-y-6">
        <!-- Header Section -->
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-3">
            <span class="${styles.tagBg} ${styles.tagText} px-3 py-1 rounded-full text-sm font-medium">
              ${type || 'Observation'}
            </span>
            ${sections.find(s => s.includes('Pattern ID:')) ? `
              <span class="bg-gray-100 text-gray-600 px-3 py-1 rounded-full text-sm font-medium">
                ${sections.find(s => s.includes('Pattern ID:')).replace('Pattern ID:', '').trim()}
              </span>
            ` : ''}
          </div>
        </div>

        <!-- Key Finding Section -->
        ${sections.find(s => s.includes('Key Finding:')) ? `
          <div class="relative">
            <div class="absolute -left-3 top-0 bottom-0 w-1 bg-gradient-to-b ${type === 'Warning' ? 'from-amber-500 to-orange-500' : 'from-blue-500 to-indigo-500'}"></div>
            <div class="pl-4">
              <h3 class="text-xl font-semibold text-gray-900 mb-2">Key Finding</h3>
              <p class="text-gray-700 leading-relaxed">
                ${sections.find(s => s.includes('Key Finding:')).replace('Key Finding:', '').trim()}
              </p>
            </div>
          </div>
        ` : ''}

        <!-- Details Section -->
        ${sections.find(s => s.includes('Details:')) ? `
          <div class="${styles.bgColor} border ${styles.borderColor} rounded-lg p-4">
            <div class="flex items-center mb-3">
              ${styles.icon}
              <span class="ml-2 font-semibold ${styles.textColor}">Analysis Details</span>
            </div>
            <p class="text-gray-700 leading-relaxed">
              ${sections.find(s => s.includes('Details:')).replace('Details:', '').trim()}
            </p>
          </div>
        ` : ''}

        <!-- Action Items Section -->
        ${actionItems.length > 0 ? `
          <div class="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
            <h3 class="text-lg font-semibold text-gray-900 mb-3">Recommended Actions</h3>
            <div class="space-y-4">
              ${actionItems.map(item => `
                <div class="flex items-start gap-3 p-2 hover:bg-gray-50 rounded-lg transition-colors">
                  <div class="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-indigo-100 text-indigo-600">
                    <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <span class="text-gray-700 flex-1">${item}</span>
                </div>
              `).join('')}
            </div>
          </div>
        ` : ''}
      </div>
    `;
  };

  const InsightTile = ({ story, index, onClick }) => {
    const storyType = getStoryType(story);
    const sections = story.content.split('\n').filter(s => s.trim());
    
    // Extract section content
    const headline = sections.find(s => s.includes('Key Finding:'))?.replace('Key Finding:', '').trim();
    const businessImpact = sections.find(s => s.includes('Business Impact:'))?.replace('Business Impact:', '').trim();
    
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        onClick={onClick}
        className={`group cursor-pointer relative overflow-hidden p-6 rounded-2xl shadow-md border
          bg-gradient-to-br ${storyType.bgClass} hover:shadow-2xl transform transition-all duration-300 
          hover:scale-[1.02] hover:-translate-y-1`}
      >
        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 w-32 h-32 opacity-10 transform rotate-12">
          {storyType.decorativeIcon}
        </div>
        
        {/* Story Type Badge */}
        <div className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium 
          ${storyType.tagClass} mb-4 shadow-sm group-hover:shadow transition-shadow duration-200`}>
          {storyType.icon}
          <span className="ml-2">{storyType.label}</span>
        </div>

        {/* Content */}
        <div className="relative z-10">
          <div className="flex items-start justify-between mb-3">
            <h3 className="text-xl font-semibold text-gray-900 leading-tight line-clamp-2">
              {headline}
            </h3>
            <span className={`flex-shrink-0 ml-4 ${storyType.numberClass} font-mono text-sm`}>
              #{index + 1}
            </span>
          </div>
          
          {businessImpact && (
            <p className="text-sm text-gray-600 line-clamp-2 mb-2">
              {businessImpact}
            </p>
          )}

          <div className="mt-4 flex items-center text-sm text-gray-500">
            <span className="inline-flex items-center hover:text-gray-700 transition-colors duration-200">
              Read more
              <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </div>
        </div>

        {/* Bottom Gradient Bar */}
        <div className={`absolute bottom-0 left-0 right-0 h-1.5 
          bg-gradient-to-r ${storyType.gradientClass} opacity-75 group-hover:opacity-100 
          transition-opacity duration-200`} />
      </motion.div>
    );
  };

  const EmptyState = ({ onClick }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-2xl mx-auto mt-12"
    >
      <div className="relative group">
        {/* Decorative background */}
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-2xl 
          transform group-hover:scale-105 transition-transform duration-300 ease-out" />
        
        <motion.button
          onClick={onClick}
          className="relative w-full p-8 bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl 
            hover:shadow-2xl transition-all duration-300"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="text-center space-y-6">
            {/* Animated icon */}
            <motion.div
              animate={{
                y: [0, -8, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="inline-block"
            >
              <SparklesIcon className="mx-auto h-16 w-16 text-indigo-500" />
            </motion.div>

            <div className="space-y-4">
              <h3 className="text-2xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 
                bg-clip-text text-transparent">
                Discover Your Data Stories
              </h3>
              
              <p className="text-gray-600 max-w-md mx-auto">
                Transform your metrics into meaningful insights. Our AI-powered analysis will help you 
                uncover trends, achievements, and opportunities.
              </p>

              {/* Call to action */}
              <div className="pt-4">
                <span className="inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 
                  bg-indigo-50 rounded-full group-hover:bg-indigo-100 transition-colors duration-200">
                  Start Analysis
                  <motion.svg 
                    className="ml-2 h-4 w-4"
                    animate={{
                      x: [0, 4, 0],
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </motion.svg>
                </span>
              </div>
            </div>
          </div>
        </motion.button>
      </div>
    </motion.div>
  );

  return (
    <div className="bg-white shadow overflow-visible sm:rounded-lg relative">
      {/* Header section */}
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mr-6">Data Story</h3>
          <NewFeatureFlag 
            tooltipText="AI-powered insights that analyze your metrics to uncover meaningful patterns and trends. Powered by GPT-4, with complete privacy - no identifiable user or company data is shared with OpenAI." 
          />
        </div>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Discover meaningful insights from your metrics
        </p>
      </div>

      {/* Main content section */}
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="py-4 sm:py-5 sm:px-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
              {error}
            </div>
          )}

          {!metricsData?.quarterlyData || Object.keys(metricsData.quarterlyData).length === 0 ? (
            <div className="text-center">
              <p className="text-gray-500">
                No metrics data available. Please add some metrics first.
              </p>
            </div>
          ) : isAnalyzing ? (
            <div className="mt-8 text-center">
              <div className="max-w-md mx-auto space-y-6">
                {/* Title */}
                <motion.h3 
                  className="text-xl font-semibold text-gray-900 bg-gradient-to-r from-indigo-500 
                    to-purple-500 bg-clip-text text-transparent"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  Discovering Insights
                </motion.h3>

                {/* Preview Cards */}
                <div className="relative">
                  <InsightPreviewCards />
                </div>

                {/* Animated icon and progress */}
                <div className="space-y-4">
                
                

                  {/* Progress bar */}
                  <div className="w-full max-w-xs mx-auto">
                    <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                      <motion.div
                        className="h-full bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 
                          background-animate rounded-full"
                        animate={{ width: `${progress}%` }}
                        transition={{ duration: 0.8 }}
                      />
                    </div>
                    <div className="mt-2 text-sm text-gray-600">
                      {progress < 100 ? 'Hang tight, this can take 30 seconds...' : 'Analysis complete!'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : stories.length > 0 ? (
            <AnimatePresence mode="wait">
              {!selectedStory ? (
                <motion.div
                  key="grid"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                >
                  {stories.map((story, index) => {
                    return (
                      <InsightTile
                        key={`story-${index}-${story.type}-${story.finding.substring(0, 20)}`}
                        story={story}
                        index={index}
                        onClick={() => {
                          setSelectedStory(story);
                          setDisplayedText(formatStoryContent(story.content));
                        }}
                      />
                    );
                  })}
                </motion.div>
              ) : (
                <motion.div
                  key="detail"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="mt-8 bg-white rounded-lg shadow-lg p-6"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold text-gray-900">
                      Insight Details
                    </h2>
                    <button
                      onClick={() => setSelectedStory(null)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div 
                    className="prose max-w-none"
                    dangerouslySetInnerHTML={{ __html: displayedText }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          ) : (
            <div className="mt-8 text-center">
              <div className="max-w-md mx-auto space-y-8">
                

                {/* Preview Cards */}
                <InsightPreviewCards />

                <div className="space-y-6">
                  <motion.button
                    onClick={analyzeData}
                    className="inline-flex items-center px-6 py-3 border border-transparent 
                      text-base font-medium rounded-md shadow-sm text-white 
                      bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 
                      hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                      focus:ring-indigo-500 transition-all duration-200"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <SparklesIcon className="h-5 w-5 mr-2" />
                    Start Analysis
                  </motion.button>

                  {/* Compact legend */}
                  <div className="flex justify-center space-x-6 text-sm text-gray-600">
                    <div className="flex items-center space-x-2">
                      <div className="w-2 h-2 rounded-full bg-emerald-500" />
                      <span>Success Stories</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="w-2 h-2 rounded-full bg-amber-500" />
                      <span>Improvements</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="w-2 h-2 rounded-full bg-blue-500" />
                      <span>Observations</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataStory; 