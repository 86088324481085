import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PersonalDetailsForm from './PersonalDetailsForm';
import CompanyInfoForm from './CompanyInfoForm';
import PeopleMetricsForm from './PeopleMetricsForm';
import MetricsSelectionForm from './MetricSelectionForm';
import { completeOnboarding, updateUser } from '../../util/db';
import { useAuth } from '../../util/auth';
import '../../styles/OnboardingSteps.css'; // Importing the new CSS file
import { sendSlackNotification } from '../../util/slackNotification';

export default function OnboardingSteps() {
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const auth = useAuth();
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  const handleMetricsSelection = (selected) => {
    setSelectedMetrics(selected);
  };
  

  const steps = [
    { id: 'Step 1', name: 'Your Details', status: currentStep === 0 ? 'current' : currentStep > 0 ? 'complete' : 'upcoming' },
    { id: 'Step 2', name: 'Company Info', status: currentStep === 1 ? 'current' : currentStep > 1 ? 'complete' : 'upcoming' },
    { id: 'Step 3', name: 'Choose Your Metrics', status: currentStep === 2 ? 'current' : currentStep > 2 ? 'complete' : 'upcoming' }, // New step
    { id: 'Step 4', name: 'Unlock your dashboard', status: currentStep === 3 ? 'current' : 'upcoming' }, // Updated existing step 3 to step 4
  ];
  

  const handleNextStep = async () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      try {
        if (!auth?.user?.id) {
          throw new Error('User ID not available for onboarding completion');
        }

        // Wait for onboarding completion
        await completeOnboarding(auth.user.id);
        
        // Force refresh auth user data to get updated onboardingCompletedAt
        await auth.refreshUser();
        
        // Now navigate
        history.push('/dashboard');
        
      } catch (error) {
        console.error('Failed to complete onboarding:', error);
        // Show error to user
        alert('Failed to complete onboarding. Please try again.');
      }
    }
  };

  const handleStepNavigation = (index) => {
    if (index <= currentStep) {
      setCurrentStep(index);
    }
  };

  const renderCurrentForm = () => {
    switch (currentStep) {
      case 0:
        return <PersonalDetailsForm onNextStep={handleNextStep} />;
      case 1:
        return <CompanyInfoForm onNextStep={handleNextStep} />;
      case 2:
        return <MetricsSelectionForm onNextStep={handleNextStep} onMetricsSelect={handleMetricsSelection} />; // New step
      case 3:
        return <PeopleMetricsForm onNextStep={handleNextStep} selectedMetrics={selectedMetrics} />; // Updated step number
      default:
        return <div className="message-error">Error: Invalid step</div>;
    }
  };
  

  const calculateProgressWidth = () => {
    const stepPercentage = 100 / steps.length;
    return `${stepPercentage * (currentStep + 1)}%`;
  };

  return (
    <div className="onboarding-container">
      <style>
        {`.step-list::before { width: ${calculateProgressWidth()}; }`}
      </style>
      
      <nav aria-label="Progress" className="progress-nav">
        <ol className="step-list">
          {steps.map((step, index) => (
            <li key={index} className={`${step.status}-step md:flex-1`}>
              <button
                className={`text-sm font-medium ${step.status}-step`}
                aria-current={step.status === 'current' ? 'step' : undefined}
                onClick={() => handleStepNavigation(index)}
                disabled={step.status === 'upcoming'} // Disable button for upcoming steps
              >
                {step.name}
              </button>
            </li>
          ))}
        </ol>
      </nav>
      <div className="form-container">
        {renderCurrentForm()}
      </div>
    </div>
  );
}

